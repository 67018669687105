import React, { Component } from "react";
import Header from "../../../components/header/Header";
import Greeting from '../../greeting/Greeting'
import Skills from "../../skills/Skills"
import Footer from '../../../components/footer/Footer'
import TopButton from '../../../components/topButton/TopButton'

class Home extends Component {
     render() {
          return (
               <div>
                    <Header theme={this.props.theme} />
                    <Greeting theme={this.props.theme} />
                    <Skills theme={this.props.theme} />
                    <Footer theme={this.props.theme} />
                    <TopButton theme={this.props.theme} />
               </div>
          )
     }
}

export default Home
